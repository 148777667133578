@charset "UTF-8";

/* Font Awesome license: https://fontawesome.com/license , https://fontawesome.com/license/free */

/*------------START OF CSS RESET---------------*/
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        width: auto;
        margin: 0 auto;
        line-height: 1;
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    b {
        font-weight: bold;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    h1 {
        font-size: 42px;
    }
    h2 {
        font-size: 20px;
    }
    h3 {
        font-size: 32px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 18px;
    }
    h6 {
        font-size: 16px;
    }
    input {
        cursor: pointer;
    }
    i {
        font-style: italic;
    }
/*------------END OF CSS RESET---------------*/

/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!!!!!___________________!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!!!!/                   \!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!!!***********************!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!|    START OF THE CODE    |!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!!!***********************!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!!!/_____________________\!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!!!!/                   \!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/

/******* START OF GENERAL CLASSES *******/
    html {
        scroll-behavior: smooth;
    }
    .left {
        float: left;
    }
    .right {
        float: right;
    }
    .clearfix:after {
        content: "";
        display: table;
        clear: both;
    }
    .center {
        margin: 0 auto !important;
        text-align: center !important;
    }
/******* END OF THE GRID *******/

/******* START OF THE GRID *******/
    /* Defining the grid */
        .grid {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: repeat(12, 1fr);
            grid-gap: 20px;
            margin: 0 auto;
        }
    /* END OF DEFINING THE GRID */

    /* Centering grid columns */
        .grid-col-center {
            align-self: center;
            justify-self: center;
        }
    /* END OF CENTERING GRID COLUMNS */

    /*** Setting the grid columns ***/

        /* Grid all columns*/
            .grid-col-all {grid-column: 1/-1;}
        /* END OF GRID ALL COLUMNS */

        /* Grid column #1 types*/
            .grid-col-1 {grid-column: 1;}.grid-col-1-2 {grid-column: 1/2;}.grid-col-1-3 {grid-column: 1/3;}
            .grid-col-1-4 {grid-column: 1/4;}.grid-col-1-5 {grid-column: 1/5;}.grid-col-1-6 {grid-column: 1/6;}
            .grid-col-1-7 {grid-column: 1/7;}.grid-col-1-8 {grid-column: 1/8;}.grid-col-1-9 {grid-column: 1/9;}
            .grid-col-1-10 {grid-column: 1/10;}.grid-col-1-11 {grid-column: 1/11;}.grid-col-1-12 {grid-column: 1/12;}
            .grid-col-1-13 {grid-column: 1/13;}
        /* END OF GRID COLUMN #1 TYPES */

        /* Grid column #2 types*/
            .grid-col-2-all {grid-column: 2/-1;}
            .grid-col-2 {grid-column: 2;}.grid-col-2-3 {grid-column: 2/3;}.grid-col-2-4 {grid-column: 2/4;}
            .grid-col-2-5 {grid-column: 2/5;}.grid-col-2-6 {grid-column: 2/6;}.grid-col-2-7 {grid-column: 2/7;}
            .grid-col-2-8 {grid-column: 2/8;}.grid-col-2-9 {grid-column: 2/9;}.grid-col-2-10 {grid-column: 2/10;}
            .grid-col-2-11 {grid-column: 2/11;}.grid-col-2-12 {grid-column: 2/12;}.grid-col-2-13 {grid-column: 2/13;}
        /* END OF GRID COLUMN #2 TYPES */

        /* Grid column #3 types*/
            .grid-col-3-all {grid-column: 3/-1;}
            .grid-col-3 {grid-column: 3;}.grid-col-3-4 {grid-column: 3/4;}.grid-col-3-5 {grid-column: 3/5;}
            .grid-col-3-6 {grid-column: 3/6;}.grid-col-3-7 {grid-column: 3/7;}.grid-col-3-8 {grid-column: 3/8;}
            .grid-col-3-9 {grid-column: 3/9;}.grid-col-3-10 {grid-column: 3/10;}.grid-col-3-11 {grid-column: 3/11;}
            .grid-col-3-12 {grid-column: 3/12;}.grid-col-3-13 {grid-column: 3/13;}
        /* END OF GRID COLUMN #3 TYPES */

        /* Grid column #4 types*/
            .grid-col-4-all {grid-column: 4/-1;}
            .grid-col-4 {grid-column: 4;}.grid-col-4-5 {grid-column: 4/5;}.grid-col-4-6 {grid-column: 4/6;}
            .grid-col-4-7 {grid-column: 4/7;}.grid-col-4-8 {grid-column: 4/8;}.grid-col-4-9 {grid-column: 4/9;}
            .grid-col-4-10 {grid-column: 4/10;}.grid-col-4-11 {grid-column: 4/11;}.grid-col-4-12 {grid-column: 4/12;}
            .grid-col-4-13 {grid-column: 4/13;}
        /* END OF GRID COLUMN #4 TYPES */

        /* Grid column #5 types */
            .grid-col-5-all {grid-column: 5/-1;}
            .grid-col-5 {grid-column: 5;}.grid-col-5-6 {grid-column: 5/6;}.grid-col-5-7 {grid-column: 5/7;}
            .grid-col-5-8 {grid-column: 5/8;}.grid-col-5-9 {grid-column: 5/9;}.grid-col-5-10 {grid-column: 5/10;}
            .grid-col-5-11 {grid-column: 5/11;}.grid-col-5-12 {grid-column: 5/12;}.grid-col-5-13 {grid-column: 5/13;}
        /* END OF GRID COLUMN #5 TYPES */

        /* Grid column #6 types */
            .grid-col-6-all {grid-column: 6/-1;}
            .grid-col-6 {grid-column: 6;}.grid-col-6-7 {grid-column: 6/7;}.grid-col-6-8 {grid-column: 6/8;}
            .grid-col-6-9 {grid-column: 6/9;}.grid-col-6-10 {grid-column: 6/10;}.grid-col-6-11 {grid-column: 6/11;}
            .grid-col-6-12 {grid-column: 6/12;}.grid-col-6-13 {grid-column: 6/13;}
        /* END OF GRID COLUMN #6 TYPES */

        /* Grid column #7 types */
            .grid-col-7-all {grid-column: 7/-1;}
            .grid-col-7 {grid-column: 7;}.grid-col-7-8 {grid-column: 7/8;}.grid-col-7-9 {grid-column: 7/9;}
            .grid-col-7-10 {grid-column: 7/10;}.grid-col-7-11 {grid-column: 7/11;}.grid-col-7-12 {grid-column: 7/12;}
            .grid-col-7-13 {grid-column: 7/13;}
        /* END OF GRID COLUMN #7 TYPES */

        /* Grid column #8 types */
            .grid-col-8-all {grid-column: 8/-1;}
            .grid-col-8 {grid-column: 8;}.grid-col-8-9 {grid-column: 8/9;}.grid-col-8-10 {grid-column: 8/10;}
            .grid-col-8-11 {grid-column: 8/11;}.grid-col-8-12 {grid-column: 8/12;}.grid-col-8-13 {grid-column: 8/13;}
        /* END OF GRID COLUMN #8 TYPES */

        /* Grid column #9 types */
            .grid-col-9-all {grid-column: 9/-1;}
            .grid-col-9 {grid-column: 9;}.grid-col-9-10 {grid-column: 9/10;}.grid-col-9-11 {grid-column: 9/11;}
            .grid-col-9-12 {grid-column: 9/12;}.grid-col-9-13 {grid-column: 9/13;}
        /* END OF GRID COLUMN #9 TYPES */

        /* Grid column #10 types */
            .grid-col-10-all {grid-column: 10/-1;}
            .grid-col-10 {grid-column: 10;}.grid-col-10-11 {grid-column: 10/11;}.grid-col-10-12 {grid-column: 10/12;}
            .grid-col-10-13 {grid-column: 10/13;}
        /* END OF GRID COLUMN #10 TYPES */

        /* Grid column #11 types */
            .grid-col-11-all {grid-column: 11/-1;}
            .grid-col-11 {grid-column: 11;} .grid-col-11-12 {grid-column: 11/12;}.grid-col-11-13 {grid-column: 11/13;}
        /* END OF GRID COLUMN #11 TYPES */

        /* Grid column #12 types */
            .grid-col-12-all {grid-column: 12/-1;}
            .grid-col-12 {grid-column: 12;}.grid-col-12-13 {grid-column: 12/13;}
        /* END OF GRID COLUMN #12 TYPES */

    /*** END OF SETTING THE GRID COLUMNS ***/

/******* END OF THE GRID *******/

/******* START OF GENERAL SECTION CLASSES *******/
    .section {
        margin: var(--section-bg-s1-margin);
        padding: var(--section-bg-s1-padding);
        min-height: var(--section-bg-s1-min_height);
        max-height: var(--section-bg-s1-max_height);
        background-color: var(--section-bg-s1-bg_color);
        background-image: var(--section-bg-s1-bg_image);
        background-attachment: var(--section-bg-s1-bg_attachment);
        background-position: var(--section-bg-s1-bg_position);
        background-repeat: var(--section-bg-s1-bg_repeat);
        background-blend-mode: var(--section-bg-s1-bg_blend_mode);
        background-size: var(--section-bg-s1-bg_size);
        box-shadow: var(--section-bg-s1-box_shadow);

        p, h1, h2, h3, h4, h5, h6 {
            margin: var(--section-text-margin);
            padding: var(--section-text-padding);
            display: var(--section-text-display);
            color: var(--section-text-color);
            font-family: var(--section-font_family);
            font-size: var(--section-font_size);
            font-weight: var(--section-font_weight);
            font-style: var(--section-font_style);
            text-decoration: var(--section-text_decoration);
            text-align: var(--section-text_align);
            text-shadow: var(--section-text_shadow);
            line-height: var(--section-text-line_height);    
        }
    }



/******* END OF GENERAL SECTION CLASSES *******/

/******* START OF WEBSITE SCROLLBAR *******/
    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #dbdbdb;
        border-radius: 1px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #278cd4;
        border-radius: 1px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #e0b700;
    }
/******* END OF WEBSITE SCROLLBAR *******/

@media screen and (max-width: 1000px) {
    .wrapper {
        max-width: 100%;
    }
    .grid {
        display: block;
        margin: 0 auto;
        text-align: center;
    }
    .blurb-style-s {
        padding-bottom: 80px;
    }
    .section-image img{
        margin-top: 20px;
    }
    .hero-section h1 {
        font-size: 6vh;
    }
    .hero-section h2 {
        font-size: 4vh;
    }
}

/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!!!!!___________________!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!!!!/                   \!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!!!***********************!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!!|    END OF THE CODE    |!!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!!!***********************!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!!!/_____________________\!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!!!!/                   \!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/